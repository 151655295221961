@import 'mixins';

.doc-type-attachment-flow{
    margin: 20px 0;
    .new-button {
        margin-top: 20px;

        @include btn-with-icon;
    }
}

.attachment-config-card {
    @include card;
    display: flex;
    margin: 0;
    margin-top: 20px;

    .action-buttons{
        display: flex;
        flex-basis: 100px;
        justify-content: flex-end;
        div{
            align-items: flex-start;
            display: inline-flex;
            justify-content: center;
        }
        .line{
            background-color: #C7C7C7;
            height: 25px;
            margin: 2px 20px;
            width: 0.86px;
        }
    }

    .form-section{
        display: flex;
        flex-direction: column;
        width: 100%;
        & > div {
            align-items: center;
            display: flex;
            margin: 12px 0;
            label {
                order: -1;
                padding: 0 0.5em;
                width: 10em;
            }

            label + * {
                flex: 0 0 400px;
                order: 2;
            }

            label + .toggle {
                flex: none;
                order: 2;
            }

            .ui-select-type {
                select{
                    width: 400px;
                }
            } 
            .select-handler{
                display: flex;
                order: 3;
                padding: 12px;
                label, label + * {
                    flex: 0 0 auto;
                    order: 0;
                }
            }
        }

        .role-dnd{
            border: 1px solid #DFE0EB;
            border-radius: 8px;
            padding: 12px;
            width: 100%;

            .role-dnd-hint{
                font-size: 11px;
                font-weight: 500;
                letter-spacing: 0.2px;
                line-height: 20px;
            }

            .role-item{
                align-items: center;
                display: flex;
                justify-content: space-between;
                margin: 12px;

                h5{
                    align-items: center;
                    background-color: #EFEFEF;
                    border-radius: 100px;
                    display: flex;
                    justify-content: space-between;
                    padding: 12px;
                    width: 90%;
                }
            }
        }
        .suffix, .add-btn {
            margin-left: 12px ;
            order: 3;
        }
    }

    .form-section > div{
        align-items: center;
        display: flex;
    }
}