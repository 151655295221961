@import 'mixins';
@import 'media-queries';

.view-attachments {
    .attachment {
        .attachment__list {
            align-items: flex-start;
            display: flex;
            margin: 20px 0;
            position: relative;
            align-items: center;
            @include respond-below(xs) {
                flex-direction: column;
            }

            & .attachment__type {
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0.2px;
                line-height: 20px;
                order: -1;
                padding: 0 0.5em;
                width: 12em;
                &.mandatory-field {
                    &::after {
                        margin-left: 0;
                        top: auto;
                    }
                }
            }

            & .attachment__info {
                align-items: center;
                display: flex;
                flex: 1 1 100px;
                flex-direction: row;
                margin: 12px 0;
                order: 2;

                &:first-child {
                    margin-top: 0;
                }

                .document-icon {
                    height: 34px;
                    width: 34px;
                }

                .download-icon {
                    background-color: #ffb900;
                    border-radius: 100px;
                    cursor: pointer;
                    height: 24px;
                    margin-left: 12px;
                    position: relative;
                    width: 24px;

                    svg {
                        position: absolute;
                        @include align-center;
                    }
                }

                .attachment__detail {
                    h4 {
                        cursor: pointer;
                        font-style: normal;
                    }
                    @include respond-below(xs) {
                        font-size: 12px;
                        line-break: anywhere;
                        padding: 8px;
                    }

                    span {
                        color: #333;
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }

            & .attachment-list {
                display: flex;
                flex-direction: column;
                border: 1px solid #dfe0eb;
                border-radius: 10px;
                margin-top: 10px;
                flex-grow: 1;
                margin-right: 10px;

                .files-header {
                    background-color: #f3f3f3;
                }
                .attachment-item {
                    border-bottom: 1px solid #dfe0eb;
                    padding: 10px;
                    color: #0b64e9;
                    display: flex;
                    align-items: center;
                    .attachment-name {
                        display: flex;
                        flex-basis: 35%;
                        align-items: center;
                        justify-content: center;
                        div {
                            display: flex;
                            cursor: pointer;
                            align-items: center;
                        }
                    }
                    .doc-description {
                        color: $black;
                        flex-basis: 35%;
                        font-size: 12px;
                        letter-spacing: 0.2px;
                        text-align: center;
                    }
                    .uploaded {
                        color: $black;
                        flex-basis: 30%;
                        font-size: 12px;
                        letter-spacing: 0.2px;
                        text-align: center;
                    }
                    .file-size {
                        color: #808080;
                        font-size: 12px;
                    }
                    &:last-child {
                        border-bottom: none;
                    }
                }
                &:empty {
                    display: none;
                }
            }
        }
    }
    .no-attachment {
        color: #757575;
        font-size: 14px;
        margin: 12px;
    }
}
