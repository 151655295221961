@import 'mixins';
@import 'media-queries';

.dashboard {
    margin: 0 var(--side-width);
    @include tabs;

    .tab {
        // ! REVIEWER-FIX
        overflow: auto;
        .reviewers {
            display: none;
        }
        .tab-icons {
            width: 16px;
        }
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .tab::-webkit-scrollbar{
        display: none;
    }

    .section {
        margin-top: var(--side-width);
        .filters-section {
            @include card;
            margin: 0;
        }

        .filters-section {
            margin-right: 20px;
            min-width: 260px;
            width: 260px;

            @include respond-below('sm') {
                display: none;
            }
        }

        .doc-list {
            flex-grow: 1;
        }
    }
}
