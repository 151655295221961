@import 'mixins';
@import 'variables';
.parentTree, .childTree {
    margin-bottom: 50px;
    .refer-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include tabs;
        .ref-tab {
            background-color: #e5e5e5 !important;
            border-radius: 100px !important;
            position: absolute;
            right: 50px;
            button {
                padding: 6px 15px;
                margin: 0 !important;
                background-color: #e5e5e5 !important;
                &:hover {
                    background-color: #f37321 !important;
                    color: #fff !important;
                }
            }
        }
    }
    .rd3t-tree-container {
        border: 1px solid black;
        border-radius: 50px;
        margin-top: 10px;
    }
}

.node__root > circle {
    r: 5;
}

.node__root div {
    background-color: #6fb006 !important;
}

.nodeDiv {
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: #4682b4;
    color: #fff;
    margin-right: 50px;
    h3 {
        padding-top: 10px;
    }
}

.node__branch > circle {
    r: 5;
}

.node__leaf > circle {
    r: 5;
}

.node__link {
    stroke: #216d09;
    stroke-width: 0.2rem;
}
.ref-loading-state {
    .react-loading-skeleton {
        margin-left: 40%;
        margin-top: 10%;
        line-height: 40px;
    }
}
