@import 'mixins';
@import 'variables';

.document-types{
    margin: 0 var(--side-width);

    .top-section{
        align-items: center;
        display: flex;
        justify-content: flex-end;
        
        .title{
            margin-right: auto;
            h1 {
                font-size: 30px;
                font-weight: 500;
                line-height: 130%;
            }
        }
        @include tabs;
        .tab{
            button {
                padding: 6px 20px;
            }
        }

    }
    h6{
        font-weight: 400;
    }
}