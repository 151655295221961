@import 'variables';
@import 'mixins';
@import 'media-queries';

.search-card {
    padding: 14px 0;
    // @include card;
    position: relative;
    &:first-child {
        margin: 0;
        padding-top: 0;
    }

    &::after {
        border-bottom: 1px solid #cfcfcf;
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
    }
    &:last-child {
        &::after {
            border: none;
        }
    }

    &__header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        @include respond-below(xs) {
            flex-direction: column;
            align-items: flex-start;
        }
        .header-title {
            display: flex;
            gap: 10px;
            align-items: center;
            h2 {
                color: $blue;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                @include respond-below(xs) {
                    max-width: 100% !important;
                }
            }
        }

        .header-right-part {
            align-items: center;
            display: flex;
            .favorite-component,
            .card-info-item {
                margin-left: 12px;
            }
            .fav-copy {
                align-items: center;
                display: flex;
                h4 {
                    white-space: pre;
                }
                svg {
                    height: 14px;
                    width: 14px;
                }
            }
        }
    }

    &__description {
        p {
            display: block;
            font-size: 14px;
            margin-top: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            @include respond-below(xs) {
                max-width: 100% !important;
            }
        }
    }

    .card-info-item {
        display: flex;
        font-size: 12px;
        label {
            color: #757575;
            margin-right: 6px;
            white-space: pre;
        }
        @include respond-below(xs) {
            margin-bottom: 8px;
        }
        // h4{
        //     cursor: pointer;
        // }
    }

    &__info {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        @include respond-below(xs) {
            flex-direction: column;
        }
    }

    &__highlighter {
        margin-top: 12px;
        .highlighter {
            font-size: 14px;
            margin-left: 12px;
        }
        em {
            background-color: #fffd76;
        }
        .card-info-item {
            align-items: center;
            .attachment__detail {
                h4 {
                    cursor: pointer;
                    font-style: normal;
                }
            }
            div {
                align-items: center;
                display: flex;
                white-space: pre;

                .download-icon {
                    background-color: #ffb900;
                    border-radius: 100px;
                    cursor: pointer;
                    height: 24px;
                    margin-left: 12px;
                    position: relative;
                    width: 24px;

                    svg {
                        position: absolute;
                        @include align-center;
                    }
                }
            }
        }
    }
    .header-title div {
        display: inline-block;
        height: 10px;
        line-height: 10px;
        position: relative;
        margin: 0 16px 8px 0;
        padding: 0 10px 0 12px;
        background: #00a6df;
        -webkit-border-bottom-right-radius: 3px;
        border-bottom-right-radius: 3px;
        -webkit-border-top-right-radius: 3px;
        border-top-right-radius: 3px;
        -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
        color: #fff;
        font-size: 8px;
        font-family: 'Lucida Grande', 'Lucida Sans Unicode', Verdana, sans-serif;
        text-decoration: none;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
        font-weight: bold;
    }

    .header-title div.disabled {
        pointer-events: none;
        cursor: none;
    }

    .header-title div:before {
        content: '';
        position: absolute;
        top: 3px;
        left: 2px;
        float: left;
        width: 5px;
        height: 5px;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        background: #fff;
        -webkit-box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.4);
        box-shadow: -1px -1px 2px rgba(0, 0, 0, 0.4);
    }

    //     .header-title a:after {
    //         content: "";
    // position: absolute;
    // top:0;
    // left: -12px;
    // width: 0;
    // height: 0;
    // border-color: transparent #00a6df transparent transparent;
    // border-style: solid;
    //         }
    .highlighter-btn {
        display: flex;
        justify-content: flex-end;
        button {
            bottom: 12px;
            color: $blue;
            text-decoration: underline;
        }
    }
    @include respond-below(xs) {
        .search-card {
            &__highlighter {
                & > .card-info-item {
                    display: flex;
                    flex-wrap: wrap;
                    & > .highlighter {
                        margin: 8px 0;
                    }
                }
            }
            &__header {
                margin-bottom: 1rem;
                .header-title {
                    margin-bottom: 8px;
                }
            }
        }
    }
}
