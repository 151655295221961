@import 'mixins';

.doc-type-setting-config {
    @include card;
    margin: 0;
    margin-top: 24px;

    .config-item {
        &.first {
            margin-top: 24px;
        }
        display: flex;
        align-items: center;
        // margin: 8px 0;
        padding: 12px 0;
        &--title {
            order: -1;
            width: 26em;
        }
        &--section {
            flex: 1 1 100px;
            order: 2;
            .effective-period,
            .sunset-period {
                margin-left: 1.5rem;
                max-width: 4rem;
            }
            .version-inputs {
                display: flex;
                position: relative;
                gap: 10px;
                margin-left: 1.5rem;
                input {
                    max-width: 4rem;
                }
                div {
                    p {
                        position: absolute;
                        bottom: 0;
                    }
                }
            }
        }
        .select-handler {
            display: flex;
            order: 3;
            padding: 12px;
            label,
            label + * {
                flex: 0 0 auto;
                order: 0;
            }
        }
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type='number'] {
            -moz-appearance: textfield;
        }
    }
    .generate-num-outer {
        display: flex;
        padding: 12px 0;
        label {
            order: -1;
            width: 26em;
        }
        .select-handler {
            display: flex;
            order: 3;
            padding: 0 1em;
            // padding: 12px;
            label,
            label {
                flex: 0 0 auto;
                order: 0;
                padding: 0 0.5em;
                width: 14em;
            }
        }
    }
}
