@import "variables";
@import "mixins";
@import "media-queries";
.template-model {
    max-height: 80vh;
    overflow-y: auto;
    justify-content: center;
    align-items: center;
    background-color: $white;
    border-radius: 12px;
    outline: none;
    padding: 24px 16px 24px 32px;
    position: absolute;
    width: 500px;
    @include align-center;
    max-height: 80vh;
    overflow-y: auto;
    .modal-body {
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        p {
            font-size: 15px;
            font-weight: bolder;
        }
        .add-template-input-group {
            display: flex;
            flex-direction: row;
            margin: 16px;
            padding: 5px;
            label {
                padding: 10px 0px;
            }
            select {
                width: 100%;
                background: #fff;
                border: 1px solid #dfe0eb;
                border-radius: 8px;
                box-sizing: border-box;
                padding: 12px 16px;
                outline: none;
                transition: border-color 0.3s;
            }
        }
    }
}
