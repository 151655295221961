.delegation-form {
    .create-delegation-header{
        font-size: 20px;
        padding-top: 10px;
        padding-left: 10px;
        font-weight: bold;
    }
    background-color: #fff;
    border-radius: 12px;
    margin-bottom: 12px;
    .form-row {
        display: flex;
        .form-group {
            width: 100%;
            margin: 12px;
            &.toggle-btn {
                display: grid;
            }
            &.save-btn {
                display:flex;
                justify-content: flex-end;
            }
        }
    }
}