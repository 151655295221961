@import "variables";
@import "mixins";
.document-reference {
    &.border {
        padding: 10px;
    }
    .NoDocumentsFound{
        @include card;
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 0;
        margin-bottom: 0;
        h1 {
            font-family: "Bebas Neue", cursive;
            font-size: 36px;
        }
    }
    .create-new-document {
        float: right;
        padding: 5px 10px;
    }
    .search-document {
        margin-bottom: 50px;
        display: flex;
        flex-direction: row;
        .react-select {
            width: 70%;
        }
    }
    .document-details {
        background: #eee;

        tbody::before {
            margin-top: 0;
        }
        th,
        td {
            padding: 10px;
            border-radius: 0 !important;
        }
        td {
            word-wrap: break-word;
            overflow-wrap: anywhere;
            .file-actions {
                display: flex;
                gap: 0.4rem;
                flex-basis: 30%;
                color: #000;
                div {
                    display: flex;
                    gap: 0.5rem;
                    svg {
                        background-color: orange;
                        height: 1.2rem;
                        width: 1.2rem;
                        border-radius: 50%;
                        padding: 3px 4px;
                    }
                }
                .copy-smartlink svg {
                    all: unset;
                }
            }
        }
        th {
            border-bottom: 1px solid #dfe0eb;
            padding: 12px;
        }
        .file-table {
            thead th,
            tbody td {
                font-size: 12px;
            }
        }
    }
    td,
    th {
        padding: 5px;
    }

    td {
        .files-column {
            align-items: center;
            display: flex;
            color: #2079FF;
            cursor: pointer;
            background: none;
            border: none;
            margin-left: -10px;
            svg {
                margin-left: 4px;
            }
        }
        .action {
            display: flex;
            flex-direction: row;
            span.action-icon {
                align-items: center;
                display: flex;
                width: 25px;
                svg {
                    width: 15px;
                }
                div {
                    svg {
                        margin-top: 10px;
                    }
                }
            }
        }
        .versionNumber {
            display: inline-block;
            width: 18px;
        }
        .document-phase {
            display: inline;
            border-radius: 8px;
            color: $white;
            font-family: Enphase Visuelt medium;
            font-size: 11px;
            letter-spacing: 0.5px;
            max-width: 100px;
            padding: 6px 12px;
            text-align: center;
            &.draft {
                background-color: #6c6c6c;
            }
            &.release {
                background-color: #0099ef;
            }
            &.rerelease {
                background-color: #1d4289;
            }
            &.obsolete {
                background-color: #de2100;
            }
        }
        span {
            svg {
                width: 20px;
            }
        }
        button {
            padding: 10px;
        }
    }
}

.create-document__Modal {
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    inset: 15% auto auto 50% !important;
    min-width: 200px;
    padding: 20px;
    position: absolute;
    width: 60%;
    transform: translate(-50%, -50%);
    bottom: auto;
    left: 50%;
    margin-right: "-50%";
}

.select-field {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    .react-select {
        width: 90%;
    }
}

.Yes {
    background-color: #6fb006;
    padding: 6px 12px;
    border-radius: 8px;
    color: white;
    font-family: 'Enphase Visuelt medium';
    font-size: 11px;
}

.No {
    background-color: #ff0000ad;
    padding: 6px 12px;
    font-family: 'Enphase Visuelt medium';
    border-radius: 8px;
    color: white;
    font-size: 11px;
}

