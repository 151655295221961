@import 'variables';

.doc-comments {
    .comments-list {
        margin-top: 24px;
    }
    .add-comment {
        overflow: hidden;
        textarea {
            border-radius: 8px;
            box-sizing: border-box;
            font-family: $font-family;
            margin-top: 8px;
            padding: 12px;
            resize: vertical;
            width: 100%;
        }
        .submit-comment {
            float: right;
            font-size: 12px;
            margin: 16px 0;
        }
    }
}

.comment {
    padding: 8px 0;
    &--title {
        display: inline-flex;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.02em;
        line-height: 20px;
        margin-bottom: 8px;
    }
    p {
        background: #f6f6f6;
        border: 1px solid #f6f6f6;
        border-radius: 8px;
        box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.3);
        font-size: 12px;
        font-weight: 400;
        margin: 8px 0;
        padding: 16px;
        &.approve {
            background: rgba($state-green, 0.2);
            color: $state-green;
        }
        &.reject {
            background: rgba($state-red, 0.2);
            color: $state-red;
        }
        pre {
            font-family: $font-family;
            overflow-x: auto;
            white-space: pre-wrap;
            white-space: -moz-pre-wrap;
            white-space: -pre-wrap;
            white-space: -o-pre-wrap;
            word-wrap: break-word;
        }
    }

    textarea {
        border-radius: 8px;
        box-sizing: border-box;
        font-family: $font-family;
        margin-top: 8px;
        padding: 12px;
        resize: vertical;
        width: 100%;
    }
}
