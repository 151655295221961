@import 'mixins';
@import 'variables';
@import 'media-queries';

.new-doc-detail {
    @include card;
    display: flex;
    margin-top: 40px;
    padding: 0;
    @include respond-below ('sm') {
        margin-top: 12px;
    }
}

.activity{
    background-color: transparent !important;
    box-shadow: none;
}

.doc-checklist {
    box-shadow: 0 20px 36px rgba(14, 14, 44, 0.1), 0 1px 2px rgba(0, 0, 0, 0.05);
    min-height: 320px;
    min-width: 240px;
    padding: 25px 32px;
    position: relative;

    .checklist-icon{
        bottom: 25%;
        position: absolute;
    }

    .list{
        display: flex;
        flex-direction: column;
        font-weight: 400;
        justify-content: center;
        
        .row{
            align-content: center;
            align-items: center;
            cursor: pointer;
            display: flex;
            justify-content: flex-tart;
            padding-top: 26px;

            h5{
                font-size: 12px;
                font-weight: 400;
            }

            .circle {
                background-color: #FFF;
                border-radius: 50%;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
                font-size: 20px;
                height: 24px;
                margin-right: 12px;
                position: relative;
                width: 24px;

                h5{
                    position: absolute;
                    @include align-center;
                }
            }
            &.active {
                h5{
                    color :$blue;
                }
                .circle {
                    border:1px solid $blue;
                }
            }
            &.completed {
                h5{
                    font-weight: 700;
                }
                .circle {
                    background-color: $green;
                    h5{
                        color : $white;
                    }
                }
            }
            &.checklist-error {
                h5{
                    font-weight: 700;
                }
                .circle {
                    background-color: $red;
                    h5{
                        color : $white;
                    }
                }
            }
        }
    }
}

.activityChecklist{
    background-color: white;
}