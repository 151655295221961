@import 'variables';
@import 'mixins';

.searched-params{
    @include card;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 0;
    margin-bottom: 12px;
    padding: 12px 32px;
    
    .search-pill{
        background-color: #F3F3F3;
        border-radius: 100px;
        font-size: 12px;
        padding: 8px;
        display: flex;
        .close-filter {
            width: 16px;
            margin-left: 5px;
            cursor: pointer;
        }
    }
}