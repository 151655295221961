@import "variables";

.breadcrumbs {
    margin: 12px 0;
    padding: 0 var(--side-width);

    ul {
        align-items: center;
        display: inline-flex;
        list-style-type: none;
        li {
            align-items: center;
            color: $black;
            display: flex;
            font-family: Enphase Visuelt bold;
            font-size: 12px;
            font-weight: 500;
            svg {
                height: 14px;
                width: 14px;
            }

            &:last-child {
                font-weight: 400;
                svg {
                    display: none;
                }

                span {
                    font-family: Enphase Visuelt;
                    pointer-events: none;
                    text-decoration: none;
                }
            }

            span {
                color: $black;
                cursor: pointer;
                letter-spacing: 0.02em;
                text-decoration: none;
            }
        }
    }
}
.admin-side-navigation .breadcrumbs {
    margin: 12px 0;
    margin-left: 72px;
    padding: 0 36px;
}
