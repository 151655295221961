@import 'variables';
@import 'mixins';
@import 'media-queries';

.document-search{
    background-color: $white;
    display: flex;
    
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    // margin-top: auto;
    // width: 50%;

    &__simple{
        align-items: center;
        display: flex;
        position: relative;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;    
        border-radius: 10px;
        .input-fields {
            border: 1px solid  rgba($dark-blue, 0.2) ;
            border-radius: 8px;
            display: flex;
            align-items: center;
            flex-basis: 100%;
            flex-direction: row;

            .advanced-setting{
                border-left: 0;
                border-right: 0;
                padding: 8px 12px;
                border-left: 1px solid  rgba($dark-blue, 0.2) ;
                &:hover{
                    background-color: #000;
                    color: #fff;
                    cursor: pointer;
                    transition: ease 200ms;
                }
                /* .advanced-setting-icon.top {
                    transform: rotate(180deg);
                }*/
            }
            .search-btn{
                border: none;
                padding: 8px 12px;
            }

            .doc-type-search{
                height: 38px;
                min-width: 140px;
                .select__control {
                    border: none;
                    border-radius: 8px 0 0 8px;
                    border-right: 1px solid rgba($dark-blue, 0.5);
                }
            }

            input {
                border: none;
                // ! MEMO_FIX : border-radius is added 
                border-radius: 0px;
            }
        }

        .search-btn{
            border-radius: 0 8px 8px 0;
            margin: 0;
        }
        .search-close{
            border-radius: 100px;
            cursor: pointer;
            height: 38px;
            margin: 0 16px 0 12px;
            position: relative;
            width: 38px;

            &:hover{
                background-color: #F4F3F0;
            }

            svg{
                fill: $dark-blue;
                height: 18px;
                position: absolute;
                transform: translate(-50%, -50%) rotate(45deg) !important;
                width: 18px;
                @include align-center;
            }
        }

    }

    &__advanced {
        @include card;
        align-items: flex-start;
        border-radius: 0 0 8px 8px;
        box-shadow: 0 20px 36px rgba(14, 14, 44, 0.1), 0 1px 2px rgba(0, 0, 0, 0.05);
        display: flex;
        flex-direction: column;
        margin: 0;
        min-width: 360px;
        padding: 16px;
        position: absolute;
        top: 66px;
        z-index: 2;

        @include respond-below ('sm') {
            min-width: auto;
        }

        & > div{
            align-items: center;
            display: flex;
            margin: 6px 0;
            width: 100%;
            & > .advanced-search-label {
                font-size: 12px;
                order: -1;
                padding: 0 0.5em;
                width: 10em;
            }
        
            & > .advanced-search-input {
                flex: 1 1 100px;
                order: 2;
                font-size: 14px;
            }

            @include respond-below ('sm') {
                align-items: flex-start;
                flex-direction: column;
                & > .advanced-search-label {
                    font-size: 12px;
                }
        
                & > .advanced-search-input {
                    flex: 0;
                    width: 100%;
                }
            }
        }

        .btn{
            display: flex;
            justify-content: flex-end;
            @include respond-below ('sm') {
                align-items: flex-end;
            }
            button{
                margin-right: 0;
                padding: 8px 1.5em
            }
        }
        .include-attachment{
            display: flex;
            justify-content: flex-end;
            width: 100%;
            @include respond-below ('sm') {
                flex-direction: row;
            }
            label{

                font-size: 12px;
            }
            .toggle{
                margin-right: 20px !important;
            }
        }
    }

    .doc-search{
        border-color: $dark-blue;
        padding: 11px 11px;
        border-radius: 0;
        width: 100%; 
    }

}