@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
@import 'media-queries';

:root {
    --side-width: 36px;
    @include respond-below ('sm') {
        --side-width: 12px;
    }
}