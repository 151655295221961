@import 'mixins';

.doc-type-numbering {
    align-items: baseline;
    display: flex;
    justify-content: space-between;

    .input-fields {
        align-items: baseline;
        display: flex;
        flex-wrap: wrap;
        label {
            margin: 0 12px;
        }
        & > div {
            margin-bottom: 12px;
        }
    }

    code {
        background-color: #E3E3E3;
        border: 1px solid #E3E3E3;
        border-radius: 8px;
        box-sizing: border-box;
        color: #5F6062;
        display: inline-block;
        min-width: 160px;
        padding: 8px 16px;
    }
}
