@import 'mixins';

.data-field-type{
    @include card;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0;

    .action-buttons{
        display: flex;
        flex-basis: 100px;
        justify-content: flex-end;
        div{
            align-items: flex-start;
            display: inline-flex;
            justify-content: center;
        }
        .line{
            background-color: #C7C7C7;
            height: 25px;
            margin: 2px 6px;
            width: 0.86px;
        }
    }

    .form-section{
        display: flex;
        flex-direction: column;
        width: 100%;
        & > div {
            align-items: center;
            display: flex;
            margin: 12px 0;
            label {
                order: -1;
                padding: 0 0.5em;
                width: 10em;
            }
    
            label + * {
                flex: 0 0 400px;
                order: 2;
            }

            .ui-select-type {
                select{
                    width: 400px;
                }
            } 
            .select-handler{
                display: flex;
                order: 3;
                padding: 12px;
                label, label + * {
                    flex: 0 0 auto;
                    order: 0;
                }
                align-items: center;
            }
            .quill{
                border-radius: 8px;
                .ql-toolbar {
                    border-top-right-radius: 8px;
                    border-top-left-radius: 8px;
                }
                .ql-container {
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                    font-family: Enphase Visuelt;
                }
            }
        }
    }

    .form-section > div{
        align-items: center;
        display: flex;
    }
    // TODO: need to be removed
    select{
        align-items: center;
        background-color: $white;
        border: 1px solid rgba(223, 224, 235, 1);
        border-radius: 8px;
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-end;
        min-width: 120px;
        padding: 12px 16px;

        &:disabled {
            background-color: $disabled-grey;
        }
    }
}