@import 'mixins';

.approval-stage {
    @include card;
    display: flex;
    margin: 0;
    margin-top: 20px;

    .action-buttons {
        display: flex;
        flex-basis: 100px;
        justify-content: flex-end;
        div {
            align-items: flex-start;
            display: inline-flex;
            justify-content: center;

            h5 {
                margin: 10px 5px;
            }
        }
        .line {
            background-color: #c7c7c7;
            height: 25px;
            margin: 2px 10px;
            width: 0.86px;
        }

        .delete {
            cursor: pointer;
        }
    }

    .form-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        & > div {
            align-items: center;
            display: flex;
            margin: 12px 0;
            label {
                order: -1;
                padding: 0 0.5em;
                width: 14em;
            }

            // & .due-date-value{
            //     & + .suffix, & + .add-btn {
            //         order: 3;
            //         padding-left: 12px ;
            //     }
            // }

            .toggle {
                margin: 0 0.8rem !important;
            }

            label + * {
                flex: 0 0 400px;
                order: 2;
            }

            .ui-select-type {
                select {
                    width: 400px;
                }
            }
            .select-handler {
                display: flex;
                order: 3;
                padding: 12px;
                label,
                label + * {
                    flex: 0 0 auto;
                    order: 0;
                }
            }
        }

        .role-dnd {
            border: 1px solid #dfe0eb;
            border-radius: 8px;
            padding: 12px;
            width: 100%;

            .role-dnd-hint {
                font-size: 11px;
                font-weight: 500;
                letter-spacing: 0.2px;
                line-height: 20px;
            }

            .role-item {
                align-items: center;
                display: flex;
                justify-content: space-between;
                margin: 12px;

                h5 {
                    align-items: center;
                    background-color: #efefef;
                    border-radius: 100px;
                    display: flex;
                    justify-content: space-between;
                    padding: 12px;
                    width: 90%;
                }

                .remove-icon {
                    cursor: pointer;
                }
            }
        }
        .suffix,
        .add-btn {
            margin-left: 12px;
            order: 3;
        }
    }

    .form-section > div {
        align-items: center;
        display: flex;
    }
}
