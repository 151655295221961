@import 'custom';
@import 'variables';
@import 'mixins';

@font-face {
    font-family: Enphase Visuelt bold;
    src: url('./assets/fonts/EnphaseVisuelt-Bold.woff2') format('woff2');
}

@font-face {
    font-family: Enphase Visuelt;
    src: url('./assets/fonts/EnphaseVisuelt-Regular.woff2') format('woff2');
}

@font-face {
    font-family: Enphase Visuelt medium;
    src: url('./assets/fonts/EnphaseVisuelt-Medium.woff2') format('woff2');
}

*,
*::after,
*::before {
    box-sizing: border-box;
    margin: 0;
    // outline: 1px solid rgb(0, 0, 255);
    padding: 0;
}

body {
    background-color: $body-bg;
    font-family: Enphase Visuelt;
}

body > iframe[style*='2147483647']:not([id='webpack-dev-server-client-overlay']) {
    display: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
label {
    font-family: Enphase Visuelt medium;
    font-weight: 500;
}

h1 {
    font-size: 20px;
}

h2 {
    font-size: 18px;
}

h3 {
    font-size: 16px;
}

h4 {
    font-size: 14px;
}

h5 {
    font-size: 12px;
}

label {
    font-size: 14px;
}

button {
    background: #fff;
    border: 1px solid #0a0908;
    border-radius: 100px;
    cursor: pointer;
    margin: 0 12px;
    padding: 12px 3em;

    &:hover {
        background: #3c3c3c;
        color: $white;
    }

    &:disabled {
        border: 1px solid #c4c6c4;
        color: #c4c6c4;
        cursor: not-allowed;

        &:hover {
            background-color: inherit;
        }
    }

    &.primary-btn {
        background-color: $black;
        border: none;
        color: $white;

        &:hover {
            background: #3c3c3c;
        }

        &:disabled {
            background: #c4c6c4;
            cursor: not-allowed;
        }
    }

    &.link {
        border: none;
        color: $black;
        min-width: 0 !important;
        padding: 0;

        &:hover {
            background: none;
            color: #3c3c3c;
        }

        &:disabled {
            color: 1px solid #c4c6c4;
            cursor: not-allowed;
        }
    }

    &.btn-icon {
        align-items: center;
        display: flex;

        svg {
            margin: 0 4px;
        }
    }
}

.puff-loader {
    display: block;
    margin: 0 auto;
    position: absolute;
    top: 40%;
    left: 47%;
}

input, textarea {
    background: #fff;
    border: 1px solid rgba(223, 224, 235, 1);
    border-radius: 8px;
    box-sizing: border-box;
    padding: 12px 16px;

    &:disabled {
        background: $disabled-grey;
        color: #0a0908;
        cursor: not-allowed;
    }
}

// React Toast
.Toastify__toast-body {
    white-space: pre-line;
}

// MODAL design
.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.8) !important;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2;
}

// React-select
.react-select {
    font-family: Enphase Visuelt medium;
    font-size: 12px;
    // font-weight: 500;
    line-height: 16px;

    .select__control {
        border: 1px solid #dfe0eb;
        border-radius: 8px;
        box-shadow: none;

        &.select__control--is-focused {
            border-color: $dark-blue;
            box-shadow: 0 0 0 0.2rem $primary-color;
            outline: 0;
        }

        &.select__control--is-focused {
            box-shadow: none;
        }

        .select__value-container {
            .select__multi-value {
                background-color: $body-bg;
                border-radius: 8px;

                .select__multi-value__remove {
                    border-radius: 8px;
                }
            }
        }

        .select__indicators {
            .select__indicator-separator {
                display: none;
            }

            .select__dropdown-indicator {
                color: inherit;
            }
        }
    }

    .select__menu {
        .select__menu-list {
            .select__option {
                &.select__option--is-focused {
                    background: rgba($blue, 0.4);
                }

                &.select__option--is-selected {
                    background: $blue;
                }
            }
        }
    }

    .select__input {
        input {
            padding: 4px 0 !important;
        }
    }

    &.select--is-disabled {
        .select__indicators,
        .select__multi-value__remove {
            display: none;
        }

        .select__multi-value {
            background-color: #cfcfcf !important;
        }
    }

    &.indicator-enabled{
        .select__indicators{
            display: flex;
            pointer-events: auto;
        }
    }
}

// TABLE
table {
    border-collapse: collapse;
    width: 100%;

    thead {
        @include card;

        th {
            font-family: Enphase Visuelt medium;
            font-size: 14px;
            // font-weight: 500;
            // line-height: 160%;
            padding: 12px;
            text-align: left;

            &:first-child {
                border-radius: 12px 0 0 12px;
            }

            &:last-child {
                border-radius: 0 12px 12px 0;
            }
        }
    }

    tbody {
        &::before {
            content: '';
            display: block;
            margin-top: 8px;
        }

        tr {
            background-color: $white;

            td {
                border-bottom: 1px solid #dfe0eb;
                font-size: 14px;
                letter-spacing: 0.2px;
                line-height: 20px;
                padding: 12px;
                text-align: left;
            }

            &:hover {
                td {
                    background-color: none;
                }
                background-color: #fffae0;
            }

            &:first-child {
                td:first-child {
                    border-top-left-radius: 12px;
                }

                td:last-child {
                    border-top-right-radius: 12px;
                }
            }

            &:last-child {
                td:first-child {
                    border-bottom-left-radius: 12px;
                }

                td:last-child {
                    border-bottom-right-radius: 12px;
                }
            }
        }
    }
}

// status pill
.status-pill {
    border-radius: 8px;
    color: $white;
    font-family: Enphase Visuelt medium;
    font-size: 11px;
    // font-weight: 500;
    letter-spacing: 0.5px;
    max-width: 100px;
    padding: 6px 12px;
    text-align: center;

    &.draft {
        background-color: #6c6c6c;
    }
    &.revised {
        background-color: #1d4289;
    }
    &.pending,
    &.under_review {
        background-color: $state-yellow;
    }
    &.obsolete,
    &.obsoleted {
        background-color: #de2100;
    }
    &.sunset {
        background-color: #d97031;
    }
    &.inactive {
        background-color: #2c436f;
    }
    &.submit {
        background-color: #6CB4EE;
    }
    &.create {
        background-color: #555555;
    }

    &.approved,
    &.published,
    &.completed,
    &.republished {
        background-color: $state-green;
    }

    &.rejected {
        background-color: $state-red;
    }
}

svg.star {
    cursor: pointer;

    #star-1 {
        fill: none;
        stroke: $orange;
    }

    &:hover {
        filter: drop-shadow(1px 1px 10px #000);

        #star-1 {
            fill: $orange;
        }
    }

    &.active {
        #star-1 {
            fill: $orange;
        }
    }
}

.link-style {
    color: $blue;
    cursor: pointer;
    font-family: Enphase Visuelt medium;
}

.error {
    background-color: #fff2f2;
    border-radius: 8px;
    outline: 1px solid $red;
}

.error-message {
    align-items: center;
    color: $red;
    display: flex;
    font-size: 11px;
    margin-top: 8px;

    svg {
        margin-right: 6px;
    }
}

* {
    scrollbar-width: thin;
    // scrollbar-color: blue orange;
    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: #a7a6a6;
        border-radius: 100px;
        outline: 1px solid #708090;
    }
}

.copy-text {
    align-items: center;
    background-color: $disabled-grey;
    border-radius: 8px;
    display: inline-flex;
    justify-content: flex-start;
    padding: 8px 16px;
    position: relative;

    svg {
        margin-left: 12px;
    }
}

// for modals
.close-btn {
    cursor: pointer;
    fill: #000;
    position: absolute;
    right: 16px;
    top: 16px;
    transform: rotate(45deg);
}

.unauthorized,
.not-found {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px;
    position: absolute;
    @include align-center;

    .circle {
        align-items: center;
        background: $enphase-brand-color;
        border-radius: 100%;
        display: flex;
        height: 150px;
        justify-content: center;
        width: 150px;

        h1 {
            color: #fff;
            position: absolute;
        }
    }

    h3 {
        color: $dark-blue;
        font-size: 32px;
        font-weight: 500;
        margin-top: 42px;
    }

    p {
        font-size: 18px;
        margin-top: 12px;
    }
}

.mandatory-field {
    &::after {
        color: $red;
        content: '*';
        margin-left: 2px;
        position: absolute;
        right: auto;
        top: -4px;
    }
}

.tooltip-container {
    max-width: 32ch;
}
