@import 'variables';
@import 'mixins';

.add-button{
    background: $blue;
    border-radius: 100px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: inline-block;
    height: 34px;
    position: relative;
    width: 34px;

    svg {
        fill: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0) 100%), #FFF;
        height: 13.6;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 13.6;
    }
}

.no-documents-found{
    @include card;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    margin-bottom: 0;
    padding: 320px 100px;
    h1{
        font-family: 'Bebas Neue', cursive;
        font-size: 36px;
    }
    svg{
        height: 50%;
        width: 50%;
    }
}

.no-documents-reference-found {
    @include card;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    margin-bottom: 0;
    padding: 20px 10px;
    h1{
        font-family: 'Bebas Neue', cursive;
        font-size: 24px;
    }
    svg{
        height: 50%;
        width: 50%;
    }
}
.FutureChange {
    display: flex;
    align-items: center;
}
.red-color svg {
    color: red;
}
.no-permission{
    @include card;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 320px 100px;
    h1{
        font-family: 'Bebas Neue', cursive;
        font-size: 36px;
    }
}

.no-document-history-found {
    @include card;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    margin-bottom: 0;
    padding: 100px 10px;
    h1{
        font-family: 'Bebas Neue', cursive;
        font-size: 24px;
    }
}

.tooltip-docinfo {
    background-color: #fff;
    color: black;
    border: 1px solid black;
    padding: 6px 8px 2px 8px;
    border-radius: 4px;
    max-width: 300px;
    text-align: left; 
}