@import 'variables';
@import 'mixins';

.react-tag-input{
    border: 1px solid #CCC;
    border-radius: 8px;
    min-height: 36px;
    padding: 0 8px;

    &.disabled{
        background-color: #F3F3F3;
        .tags{
            .tag{
                background-color: #CFCFCF;
                cursor: not-allowed;
            }
        }
    }
    .tag-input {
        border: none;
        border-radius: 6px;
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        font-size: inherit;
        line-height: inherit;
        outline: 0;
        padding: 4px 0 0;
        // width: 50%;

        &:focus{
            outline: transparent;
        }
    }

    .tags {
        display: flex;
        flex-wrap: wrap;
        margin: 8px 0 0;
        padding: 0;
        .tag {
            align-items: center;
            background: $body-bg;
            border-radius: 8px;
            color: #000;
            display: inline-flex;
            font-size: 14px;
            justify-content: center;
            list-style: none;
            margin: 0 8px 8px 0;
            transition: all 0.2s ease;
    
            .tag-title{
                font-size: 12px;
                margin: 2px 8px;
                padding: 0.2rem 0;
            }
            .tag-close-icon {
                cursor: pointer;
                font-size: 14px;
                height: 100%;
                padding: 0 0.7rem;
                position: relative;
                svg{
                    height: 12px;
                    position: absolute;
                    width: 12px;
                    @include align-center;
                }
                &:hover{
                    background-color: #FFBDAD;
                    border-radius: 8px;
                    color: #DE350B;
                }
            }
        }

        .input{
            background-color: transparent;
            flex-grow: 1;
        }
    }


}