@import 'mixins';
@import 'variables';

.notifications-list{
    max-height: 250px;
    overflow-y: auto;

    .notification-list-transition{
        overflow-x: hidden;
        .fade-enter{
            opacity: 0;
            transform:translateX(+100%);
        }
        .fade-enter-active {
            opacity: 1;
            transform:translateX(0);
            transition: opacity 300ms, transform 300ms;
        }
        .fade-exit{
            opacity: 1;
            transform:translateX(0);
        }
        .fade-exit-active{
            opacity: 0;
            transform:translateX(+100%);
            transition: opacity 300ms, transform 300ms;
        }
    }
    .notification{
        align-items: flex-start;
        display: flex;
        padding: 12px;
        &__icon{
            align-items: center;
            display: flex;
            flex-basis: 50px;
            position: relative;
            span{
                background-color: #E5E5E5;
                border-radius: 50%;
                font-size: 20px;
                height: 32px;
                position: relative;
                width: 32px;

                svg{
                    position: absolute;
                    @include align-center;
                }
            }
        }
        &.new {
            background-color: #FFFAE0;
        }

        &__content{
            margin-left: 12px;
            h4{
                letter-spacing: 0.2px;
                line-height: 20px;
            }
            p{
                color:#626262;
                font-size: 12px;
                letter-spacing: 0.02em;
                line-height: 130%;
            }
        }
        &.no {
            display: flex;
            justify-content: center;
        }
        &__clear{
            flex-basis: 30px;
            text-align: right;
            cursor: pointer;
        }
    }
}