@import 'variables';
@import 'mixins';
@import 'media-queries';

.hrbp-admin-settings {
    .react-select.user-search {
        margin-top: 12px;
        width: 220px;
    }
    .filter-create-button{
        display: flex;
        justify-content: space-between;
        .create-hrbp-button{
            height: 50px;
        }
    }
    .edit-icon,
    .delete {
        cursor: pointer;
        z-index: 2;
        &:hover {
            color: $blue;
        }
    }
    .roles-table {
        overflow: auto;
        margin-top: 16px;
        .alias-email {
            background-color: #e5e5e5;
            border-radius: 8px;
            margin: 8px 4px;
            border-radius: 2px;
            color: hsl(0, 0%, 20%);
            font-size: 85%;
            overflow: hidden;
            padding: 3px;
            padding-left: 6px;
            text-overflow: ellipsis;
            white-space: nowrap;
            box-sizing: border-box;
            display: inline-flex;
        }
    }
}
