@import 'mixins';
@import 'media-queries';

.file-details {
    .approvers{
        padding:20px;
    }
    @include card;
    .shimmer {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .attachment-list {
        display: flex;
        flex-direction: column;
        border: 1px solid #dfe0eb;
        border-radius: 10px;
        margin-top: 10px;
        overflow: hidden;
        .files-header {
            background-color: #f3f3f3;
        }
        .attachment-item {
            border-bottom: 1px solid #dfe0eb;
            padding: 10px 0;
            color: #0b64e9;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .attachment-name {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1rem;
                flex-basis: 30%;
                div {
                    display: flex;
                    cursor: pointer;
                    gap: 0.4rem;
                    svg {
                        background-color: orange;
                        height: 1rem;
                        width: 1rem;
                        border-radius: 50%;
                        padding: 2px;
                    }
                }
            }
            .doc-description {
                color: $black;
                flex-basis: 30%;
                font-size: 12px;
                letter-spacing: 0.2px;
                text-align: center;
            }
            .uploaded {
                color: $black;
                flex-basis: 20%;
                font-size: 12px;
                letter-spacing: 0.2px;
            }
            .actions {
                display: flex;
                gap: 0.3rem;
            }
            .file-actions {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.4rem;
                flex-basis: 30%;
                color: #000;
                div {
                    display: flex;
                    gap: 0.5rem;
                    svg {
                        background-color: orange;
                        height: 1.2rem;
                        width: 1.2rem;
                        border-radius: 50%;
                        padding: 3px 4px;
                    }
                }
                .copy-smartlink svg{
                    all: unset;
                }
            }
            .remove-icon {
                flex-basis: 10%;
                display: flex;
                justify-content: flex-end;
                color: #000;
                svg:first-of-type {
                    margin-right: 5px;
                }
            }
            .file-size {
                color: #808080;
                font-size: 12px;
            }
            &:last-child {
                border-bottom: none;
            }
        }
        &:empty {
            display: none;
        }
        .add-attachment {
            display: flex;
            justify-content: flex-end;
            padding: 0.3rem;
            button {
                padding: 10px 15px;
            }
        }
        .no-attachments h3{
            text-align: center;
            text-transform: uppercase;
            font-size: 1.5rem;
            padding-top: 1rem;
        }
    }
}
