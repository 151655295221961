@import 'variables';
.App {
    display: flex;
    flex-direction: column;
    height: 100vh;

    & > .main {
        flex: 1;
    }
    .banner {
        background-color: $enphase-brand-color;
        color: #fff;
        padding: 0.5rem 1rem;
        line-height: 1.4;
        text-align: center;
    }
}
