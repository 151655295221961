@import 'variables';
@import 'mixins';
@import 'media-queries';

/* CSS for NDAForm */
.nda-form {
    border: 1px solid;
    border-radius: 5px;
    background-color: white;
    padding: 20px;
    font-family: Arial, sans-serif; /* Use a font similar to Microsoft Office */
    color: #333; /* Text color */
    margin: 0 auto;
    width: 70%;
  }

  .form-title{
    display: flex;
    justify-content: space-between;
    .mandatory-indication{
      font-style: italic;
      font-size: 15px;
      .mandatory-icon{
        color: $red;
        margin-right: 5px;
        font-weight: bold;
      }
    }
  }
  
  .header1 {
    margin-bottom: 20px;
  }

  .header3 {
    margin-bottom: 30px;
  }

  .header4 {
    margin-bottom: 30px;
  }
  
  .NDA-Form {
    margin: 0 auto; /* Center the form horizontally */
  }
  
  .form-group {
    margin-bottom: 30px;
    .mandatory-field {
      display: block;
      font-weight: bold;
      &::after {
        color: $red;
        content: '*';
        margin-left: 2px;
        position: relative;
        right: auto;
        top: -4px;
        font-weight: lighter;
    }
    }
    .label-modal {
      display: block;
      font-weight: bold;
    }
  }

  
  .form-field {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .form-footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .feedback-message{
      font-size: 15px;
      font-style: italic;
    }
  }
  