@import 'variables';
@import 'mixins';
@import 'media-queries';

.user-header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .info{
        h6{
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 2%;
            line-height: 14.4px;
        }
        h5{
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 2%;
            line-height: 16.8px;
        }
    }

    .user-icon{
        display: flex;
        position: relative;
        align-items: normal;
        margin-left: 12px;
        margin-right: 12px;
        .icon{
            background-color: $body-bg;
            border-radius: 50%;
            font-size: 20px;
            height: 44px;
            position: relative;
            width: 44px;
            &.new-notification::after {
                background: $red;
                border-radius: 100px;
                content: '';
                height: 12px;
                position: absolute;
                right: 2px;
                top: 0;
                width: 12px;
            }
            div {
                position: absolute;
                @include align-center;
            }
        }
        .notification-count{
            background: $red;
            border-radius: 100px;
            height: 24px;
            position: absolute;
            width: 24px;
            right: -5px;
            color: white;
            font-size: 16px;
            top: -5px;
            div {
                position: absolute;
                @include align-center;
            }
        }
    }
}

.user-header-pop-up{
    background-color: $primary-color;
    border-radius: 0 0 8px 8px;
    box-shadow: -4px 4px 4px 0 rgba(0, 0, 0, 0.25);
    max-height: 300px;
    position: absolute;
    right: 0;
    top: 85px;
    width: 440px;
    z-index: 3;

    @include respond-below ('sm') {
        top: 60px;
        width: 100%;
    }
    .buttons{
        align-content: center;
        align-items: center;
        border-top: 1px solid #CBCBCB;
        display: flex;
        justify-content: space-evenly;
        button {
            border: none;
            display: flex;
            padding: 12px ;
            svg {
                margin-right:12px;
            }
            &:hover{
                background-color: inherit;
                color: inherit;
            }
        }
        span {
            background-color: #EEE;
            height: 25px;
            margin: 12px;
            width: 2px;
        }
    }
}