@import 'variables';

.Owner-Change-Modal{
    background-color: $white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    inset: 15% auto auto 50% !important;
    min-width: 200px;
    padding: 12px;
    position: absolute;
    margin-top: 20px;
    width: 60%;

    & > .note{
        font-family: Enphase Visuelt;
        font-size: 11px;
        padding: 4px 0;
    }

    .summary-container {
        margin-top: 15px;
        .loader {
            display: flex;
            justify-content: center;
            margin-top: 10px;
        }
    }

    .Docs, .withdraw-summary {
        margin: 12px 0 12px 50px;
    }

    .success-msg {
        display: flex;
        align-items: center;
        gap: 5px;
        h4 {
            display: flex;
            gap: 5px;
            align-items: center;
        }
    }

    .Note {
        margin-top: 12px;
        color: red;
    }

    .button-note-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .submit-button {
        margin-top: 12px;
        width: 150px;
    }

    .react-select.user-search {
        margin-top: 12px;
    }
}