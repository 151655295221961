@import 'variables';

.sortoption {
    align-items: center;
    background-color: $white;
    border-radius: 12px;
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    label {
        font-weight: 400;
    }
    .sort-by {
        background-color: inherit;
        border: none;
        font-size: 14px;
        &:focus-visible {
            outline: none;
        }
    }
    .order-by {
        background-color: inherit;
        border: none;
        font-size: 14px;
        &:focus-visible {
            outline: none;
        }
    }

    span {
        font-size: 14px;
        margin-left: 8px;
    }

    svg {
        cursor: pointer;
        &.disabled {
            color: #757575;
            cursor: not-allowed;
        }
        &.prev {
            transform: rotate(180deg);
        }
    }
}
