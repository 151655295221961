@import 'variables';
@import 'mixins';

.document-preview-modal{
    background-color: $body-bg;
    border-radius: 24px;
    height: 95%;
    outline: none;
    overflow-y: auto;
    position: absolute;
    width: 95%;
    @include align-center;

    .preview{
        .heading{
            margin: 12px 0;
        }
        margin: 24px;
        .document-info{
            margin: 0;
        }
        .main-section{
            @include card;
            margin: 0;
            margin-top: 24px;
            padding-right: 10%;
            .reviewers {
                // ! REVIEWER-FIX 
                display: none;
                .reviewers-tab {
                    padding: 12px 32px;
                }
            }
            .attachments {
                margin-top: 12px;
            }
            .document-details, .attachments, .approvers {
                position: relative;
                .edit-icon{
                    cursor: pointer;
                    position: absolute;
                    right: 12px;
                    top: 0;
                    z-index: 2;
                    &:hover{
                        color: $blue;
                    }
                }
            }
        }
        .buttons{
            display: flex;
            justify-content: flex-end;
            margin-top: 12px;
        }
    }
    .no-approvers{
        color: #757575;
        font-size: 14px;
        margin: 12px
    }
}