@import 'variables';
@import 'mixins';
@import 'media-queries';

.reviewers-tab {
    padding: 0 36px;
    &__item {
        align-items: center;
        display: grid;
        grid-row-gap: 100px;
        grid-template-columns: 50px auto 1fr;
        margin-bottom: 30px;

        &:last-child {
            .SEQUENTIAL .arrow-icon {
                display: none;
            }
        }
        &.adhoc{
            margin-left: 4rem;
        }

        .icon-circle {
            border: 1px solid #AFAFAF;
            border-radius: 100px;
            color: #252733;
            display: flex;
            height: 34px;
            position: relative;
            width: 34px;

            &.SEQUENTIAL .arrow-icon {
                background-color: #AFAFAF;
                bottom: -18px;
                height: 2px;
                left: 50%;
                position: absolute;
                transform: translateX(-50%) rotate(90deg);
                width: calc(100% - 14px);
            }

            &.PARALLEL .arrow-icon {
                background-color: #AFAFAF;
                height: 2px;
                left: -6px;
                position: absolute;
                top: 50%;
                transform: translate(-100%, -50%);
                width: calc(100% - 14px);
            }

            &.UNDER_REVIEW {
                background-color: $state-yellow;
                border-color: $state-yellow;
                color: #FFF;
            }
            &.REJECTED {
                background-color: $state-red;
                border-color: $state-red;
                color: #FFF;
            }
            &.APPROVED, &.COMPLETED {
                background-color: $state-green;
                border-color: $state-green;
                color: #FFF;
            }

            .hour-glass-icon {
                left: 50%;
                letter-spacing: 0.2px;
                line-height: 20px;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
        @include respond-below(xs){
            .info{
                display: flex;
                flex-direction: column;
            }
        }
        .info {
            align-items: center;
            display: inline-flex;
            span {
                font-size: 12px;
                font-weight: 400;
                &::before {
                    content: ', ';
                    @include respond-below(xs){
                        content: '';
                    }
                }
            }
            .user-actions{
                display: flex;
                margin-left: 12px;
                .btn{
                    border-radius: 100px;
                    cursor: pointer;
                    height: 24px;
                    // margin: 0 6px;
                    position: relative;
                    width: 24px;
                    svg{
                        height: 16px;
                        position: absolute;
                        width: 16px;
                        @include align-center;

                    }
                    &:hover{
                        background-color: #F4F3F0;
                    }
                }
            }
            .inactive-link{
                font-size: 12px;
                margin-left: 6px;
            }

            @include respond-below(xs){
                align-items: flex-start;
            }

        }
        &.add-reviewers {
            div{
                cursor: pointer;
            }
        }
    }
}
