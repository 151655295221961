@import 'variables';
@import 'mixins';
@import 'media-queries';

.checklist{
    margin-top: 15px;
    margin-bottom: 10px;
    .question-container {
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid #dfe0eb;
        font-size: 14px;
        font-family: 'Enphase Visuelt medium';
        margin-bottom: 4px;
        .question {
            width:90%;
            .mandatory-field {
                &::after {
                    color: $red;
                    content: '*';
                    margin-left: 2px;
                    position: relative;
                    right: auto;
                    top: -4px;
                }
            }
        }
        .label {
            display: flex;
            align-items: center;
            .input{
                margin-top: -3px;
                margin-right: 2px;
            }
            .yes {
                display: flex;
                align-items: center;
                margin-right: 10px;
            }
            .no {
                display: flex;
                align-items: center;
            }
        }
    }
    .isDCO {
        width: 50%;
    }
}