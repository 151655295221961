@import 'variables';
@import 'media-queries';
@import 'mixins';

.header{
    align-items: center;
    background-color: $primary-color;
    box-shadow: 0 20px 36px -8px rgba(14, 14, 44, 0.1), 0 1px 2px rgba(0, 0, 0, 0.05);
    
    &__top-bar {
        display: flex;
        justify-content: space-between;
        padding: 20px var(--side-width);
        align-items: center;
        .header__content {
           
            display: flex;
            & button {
                padding: 10px 16px;
                border-radius: 8px;
            }
            .btn-createdoc{
                border: none;
                border-radius: 0;
                padding: 0 5% 0 10%;
                &:hover{
                    background: none;
                }
            }
            .btn-doctypes{
                border: none;
                border-radius: 0;
                padding: 0;
                &:hover{
                    background: none;
                }
            }
            .search-buttons {
                align-items: center;
                display: flex;
                gap:1em;
                margin-right: 16px;
                button {
                    margin: 0 6px;
                    :hover {
                        .search-icon {
                            fill: #FFF
                        }
                    }
                }
                .create-plus-btn.show {
                    border-radius: 8px 8px 0 0;
                    background-color: #000;
                }
                .search-icon{
                    border-radius: 100px;
                    cursor: pointer;
                    height: 38px;
                    margin: 0 6px;
                    position: relative;
                    width: 38px;
                    &:hover{
                        background: #F4F3F0;
                    }
                    svg{
                        position: absolute;
                        @include align-center;
                    }
    
                }
            }
            .dashboard-buttons{
                align-items: center;
                display: flex;
                margin-right: 16px;
                button {
                    margin: 0 6px;
                }
                .dashboard.show {
                    border-radius: 8px 8px 0 0;
                    background-color: #000;
                }
            }
            .search-buttons, .dashboard-buttons {
                &__dropdown {
                    @include card;
                    align-items: flex-start;
                    border-radius: 0 8px 8px 8px;
                    box-shadow: 0 20px 36px rgba(14, 14, 44, 0.1), 0 1px 2px rgba(0, 0, 0, 0.05);
                    display: flex;
                    flex-direction: column;
                    margin: 0 6px;
                    min-width: 150px;
                    padding: 0;
                    position: absolute;
                    top: 3.8rem;
                    z-index: 2;
                    background-color: #000;
                    display: none;

                    &.show {
                        display: block;
                    }

                    @include respond-below ('sm') {
                        min-width: auto;
                        top: 80px;
                    }

                    button {
                        width: 100%;
                        height: 2rem;
                        margin: 5px 0;
                        padding: 20px 16px;
                        border-radius: 0;
                        color: #fff;
                        background-color: #000;
                    }
                    button:hover {
                        background-color: #7D7D7D;
                    }
                    button:disabled {
                        border: none;
                    }
                }
            }
            .user-guide {
                cursor: pointer;
                // margin: 0 6px;
                margin: 0;
                height: 25px;
            }
            @include respond-below ('sm') {
                display: none;
            }
        }
        @include respond-below ('sm') {
            padding: 12px var(--side-width);
            .document-search{
                display: none;
            }
        }
    }
    &__search-bar{
        display: none;
        @include respond-below ('sm') {
            display: flex;
            margin: 0 var(--side-width) 12px var(--side-width);
            .document-search{
                width: 100%;
                .search-close{
                    display: none;
                }
            }
        }
    }
    &__mobile-doc-create-btn {
        display: none;
        @include respond-below ('sm') {
            bottom: 12px;
            display: block;
            position: fixed;
            right: 12px;
            button {
                margin: 0;
                padding: 10px 6px;  
                z-index: 99999;
            }
        }
    }
}

.logo {
    align-content: center;
    align-items: center;
    cursor: pointer;
    // display: flex;
    h1 {
        align-items: center;
        font-size: 12px;
        font-weight: 300;

    }

    span {
        background-color: #0A0908;
        height: 25px;
        margin: 2px 6px;
        width: 0.86px;
    }

    @include respond-below ('md') {
        h1{
            display: none;
        }
        span {
            display: none;
        }
    }
}