@import 'mixins';
@import 'media-queries';

@media print {
    .header {
        display: none;
    }
    
    footer.footer {
        display: none;
    }

    .document-details {
        width: 1200px;
    }
}

.table-container.prb-nomination{
    display: flex;
    flex-direction: column;
    gap: 8px;

    

    .table-1, .table-2, .table-3, .table-4{
        table-layout: fixed;
        width: 90%;
        border-spacing: 0;
        overflow-x: auto;
        margin: 0 auto;
        // .title-ana-logo{
        //     display: flex;
        //     flex-direction: row;
        // }
        .logo{
            text-align: right;
        }
        .title-header{
            text-align: center;
            font-size: 20px;
            font-weight: bold;
        }
        .headings{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        table{
            table-layout: fixed;
        }
        
        .doc-number{
            padding: 10px;
            padding-right: 0;
            font-size: large;
        }
        
        .mdp-info{
            padding: 10px;
            padding-left: 0;
            font-size: small;
        }

        .table-title{
            padding: 10px;
            padding-left: 0;
            font-size: large;
        }
        .thead th {
            background: white;
        }
        .check-box-prb-nomination{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .check-box-prb-leadership {
            display: flex;
            flex-direction: row;
        }

        .check-box-prb-leadership>div {
            margin-right: 10px;
        }

        th, td {
            padding: 5px;
            text-align: left;
            vertical-align: top;
            word-wrap: break-word;
        }

        td{
            font-size: 12px;
        }

        .confidential-footter{
            text-align: center;
        }
    }

}

.prb-nomination.breadcrumbs{
    margin: 0px;
}