@import 'variables';

.external-approver_modal{
    margin-top: 20px;
    background-color: $white;
    border-radius: 8px;
    flex-direction: column;
    inset: 15% auto auto 50% !important;
    min-width: 200px;
    padding: 12px;
    position: absolute;
    width: 40%;

    & > .note{
        font-family: Enphase Visuelt;
        font-size: 11px;
        padding: 4px 0;
    }

    .react-select.user-search {
        margin-top: 12px;
    }

    .external-approver-form{
        margin-top: 10px;
    }
}

.external-approver_modal_header{
    margin-bottom: 10px;
}

.label-modal{
    margin-right: 20px;
}

.label-input{
    width: 300px;
}

.external-modal-formGroup{
    margin-left: 0px;
    margin-bottom: 10px;
}