@import 'mixins';
@import 'media-queries';

.sunset {
    @include tabs;
    .tab {
        margin: 1rem 0;
        overflow: auto;

        .tab-icons {
            width: 16px;
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .tab::-webkit-scrollbar {
        display: none;
    }

    .section {
        margin-top: var(--side-width);
        .my-documents-tab {
            display: flex;
            flex-direction: column;
        }
        .filters-section {
            @include card;
            margin: 0;
        }

        .filters-section {
            margin-bottom: 1rem;
            .filter-list {
                > div {
                    width: 145px !important;
                }

                display: flex;
                justify-content: space-between;
                align-items: center;
                button {
                    margin-top: 1rem;
                    width: 120px;
                    font-size: 12px;
                    white-space: nowrap;
                }
            }
        }

        .doc-list {
            flex-grow: 1;
        }
    }
}
