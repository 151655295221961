@import 'variables';

.button-selector {
    display: flex;
    flex-wrap: wrap;

    .button {
        flex: 1 1 0;
        margin: 0 12px;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }

        input[type='radio'] {
            height: 0;
            visibility: hidden;
            width: 0;
        }
        label {
            border: 1px solid $black;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            min-width: 140px;
            padding: 12px 1.5em;

            &:hover {
                background-color: rgba($blue, 0.2);
            }

            &.checked {
                background-color: $blue;
                color: $white;
            }

            &.disabled {
                background-color: #F3F3F3;
                border: 1px solid #DFE0EB;
                cursor: not-allowed;
                &:hover{
                    background-color: #F3F3F3;
                }
                &.checked {
                    background-color: #C2C2C2;
                    color: $black;
                }
            }
        }
    }
}
