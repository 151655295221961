@import 'mixins';
@import 'media-queries';


.doc-type-create-info{
    @include card;
    margin: 0;
    position: sticky;
    top: 12px;
    z-index: 1;
    
    .top-section{
        display: flex;
        justify-content: flex-end;

        h1 {
            font-size: 30px;
            font-weight: 500;
            line-height: 130%;
            margin-right: auto;
        }
    }

    .section {
        margin-top: 30px;

        & > div{
            align-items: center;
            display: flex;
            margin: 20px 0;
            @include respond-below(md) {
                padding-right: 40px;
            }
            & > label {
                font-size: 12px;
                order: -1;
                width: 12em;
            }
    
            & > label + * {
                order: 2;
            }
        }
        textarea{
            border-radius: 8px;
            box-sizing: border-box;
            flex-grow: 1;
            font-family: $font-family;
            margin-top: 8px;
            padding: 12px;
            resize: none;
        }
    } 
    &__header{
        display: flex;
        justify-content: space-between;
        .left-section{
            h1{
                font-size: 30px;
            }
            P{
                font-size: 12px;
                line-height: 136.69%;
                margin-top: 16px;
            }
        }
    }
    &__version {
        align-items: center;
        display: flex;
        flex-direction: row;
        margin-top: 36px;
        label{
            font-size: 14px;
            font-weight: 500;
            order: -1;
            width: 10em;
        }
        label + *{
            flex: 0 0 auto;
            order: 2;
            width: 160px;
        }
    }

    .note{
        font-size: 11px;
    }
}