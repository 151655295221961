@import 'variables';
@import 'mixins';

.notification-setting{
    .buttons-group {
        display: flex;
        justify-content: flex-end;
        margin-top: 16px;
        :last-child {
            margin-right: 0;
        }
    }
}

.notification-setting__modal {
    align-items: center;
    background-color: $white;
    border-radius:12px ;
    display: flex;
    justify-content: center;
    outline: none;
    padding: 24px 32px;
    position: absolute;
    width: 500px;
    @include align-center;

    .heading {
        h1{
            padding: 16px;
        }
    }

    ul {
        list-style: none;
        li {
            padding: 6px;
        }
    }
    .button-group {
        margin-top: 16px;
    }
}