@import 'mixins';
@import 'variables';
@import 'media-queries';

.document-info {
    @include card;
    margin-top: 12px;

    &__heading {
        align-items: center;
        display: flex;
        justify-content: space-between;
        .title {
            h1 {
                display: inline-flex;
            }
            @include respond-below(xs) {
                display: flex;
                flex-direction: column-reverse;
                align-items: flex-start;
            }
            align-items: center;
            display: inline-flex;
            .status-pill {
                margin-left: 12px;
                white-space: pre;
                @include respond-below(xs) {
                    margin: 0 0 8px 0;
                }
            }
            .favorite-component {
                margin-left: 12px;
            }
            .title-flex {
                display: flex;
            }
        }
        .description {
            font-family: Enphase Visuelt;
            padding: 12px 0;
        }
        .sub-heading {
            margin-top: 16px;
        }
        .delegated-message {
            background-color: rgb(236, 227, 139);
            border-radius: 12px;
            font-family: Enphase Visuelt medium;
            font-size: 12px;
            padding: 6px;
        }
        .buttons {
            display: flex;
            gap: 10px;
        }
    }

    & .section {
        display: grid;
        grid-auto-rows: 0.2fr;
        grid-gap: 24px;
        // grid-auto-columns: 1fr 1fr 1fr;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        margin-top: 24px;
        @include respond-below(xs) {
            grid-template-columns: none;
        }
        &.full-width-section {
            grid-template-columns: none;
            .document-title {
                margin-left: 3.6%;
            }
            .document-description {
                margin-left: 10px;
            }
            textarea {
                border-radius: 8px;
                box-sizing: border-box;
                border: 1px solid #dfe0eb;
                font-family: $font-family;
                margin-top: 8px;
                padding: 12px;
                resize: vertical;
                width: calc(100% - 105px);
                @include respond-below(xs) {
                   width: 100%;
                }
            }
            .title-full-width {
                margin-left: 57px;
            }
        }
        .mandatory-field::after {
            right: 4px;
        }

        & > div {
            @include respond-below(xs) {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
            align-items: center;
            display: inline-flex;
            justify-content: flex-start;

            label {
                font-size: 14px;
                font-weight: 500;
                order: -1;
                padding-right: 1.2rem;
                position: relative;
            }

            label + * {
                flex: 0 0 auto;
                order: 2;
            }
            .skeleton-loading {
                width: 100%;
            }
        }
        &.one {
            input {
                flex-grow: 1;
                @include respond-below(xs) {
                    align-self: stretch;
                }
            }
        }

        #version {
            width: 210px;
        }
    }
    .detail-section {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: space-between;
        margin-top: 30px;
        @include respond-below(xs) {
            margin-top: 12px;
            flex-direction: column;
            flex-wrap: nowrap;
        }
        & > div:last-child {
            @include respond-below(xs) {
                display: flex;
                flex-direction: row;
                flex: 1;
                justify-content: space-between;
                align-items: center;
                > .toggle {
                    margin: 0 !important;
                }
            }
        }
        & > div {
            align-items: center;
            display: inline-flex;
            justify-content: flex-start;

            @include respond-below(xs) {
                display: flex;
                flex-direction: column;
                margin: 8px 0;
                align-items: flex-start;
            }

            label {
                font-size: 14px;
                font-weight: 500;
                order: -1;
                padding-right: 1.2rem;
                position: relative;
            }

            label + * {
                flex: 0 0 auto;
                order: 2;
                @include respond-below(xs) {
                    align-self: stretch;
                    justify-content: space-between;
                }
            }
        }
        #version {
            width: 210px;
            @include respond-below(xs) {
                width: calc(100%);
            }
        }
    }
    .effectivity {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 10px;
        gap: 3rem;

        /* justify-content: space-between; */
        margin-top: 30px;
        & > div {
            display: flex;
            align-items: center;
            label {
                font-size: 14px;
                font-weight: 500;
                /* order: -1; */
                padding-right: 1rem;
                position: relative;
                white-space: nowrap;
            }
            .calendar {
                width: 24px;
                margin: 0 8px;
            }
            label + * {
                flex: 0 0 auto;
                order: 2;
            }
            .react-datepicker {
                border: none;
                input {
                    width: 150px;
                    background: url('../../assets/images/calendar.svg') no-repeat 110px 10px;
                    background-size: 20px;
                }
            }
            .sunset-period {
                width: 80px;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            input[type='number'] {
                -moz-appearance: textfield;
            }
        }
        .react-datepicker__month-container {
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        }

        .approval_deadline {
            display: flex;
            gap: 0.5rem;

            input {
                order: 0;
                width: 3rem;
            }

            .datepickerclass{
                background: #f3f3f3;
                width: 108px;
            }
        }
    }
    .relation-info {
        display: flex;
        align-items: center;
        gap: 7px;
        font-size: 14px;
        color: #856404;
        background-color: #fff3cd;
        border-color: #ffeeba;
        padding: 0.25rem;
        margin-top: 10px;
        margin-bottom: 1px;
        border: solid transparent;
        border-radius: 0.35rem;

        .warning {
            .warn-icon {
                display: block;
                height: 20px;
            }
        }
    }
    .red-color {
        color : red;
        text-decoration: none;
    }
    .dot {
        height: 7px;
        width: 7px;
        background-color: #6fb006;
        border-radius: 50%;
        display: inline-block;
        margin-right: 3px;
    }
    .vertical-dash {
        width: 1px; 
        height: 20px; 
        background-color: #ccc; 
        margin-left: 8px;
        margin-right: 4px;
    }
    .space {
        margin-left: 10px;
    }
    .tooltip-docinfo {
        background-color: #fff;
        color: black;
        border: 1px solid black;
        padding: 6px 8px 2px 8px;
        border-radius: 4px;
        max-width: 300px;
        text-align: left; 
        .link-css {
            text-decoration: none;
            color: #2079FF;
            cursor: pointer;
            font-family: Enphase Visuelt medium;
        }
        .title-margin {
            margin-top: 3px;
            margin-bottom: 4px;
        }
        .light-black {
            color : rgba(0, 0, 0, 0.589)
        }
    }
}

.add-to-change {
    border-color: #f37321;
    color: #f37321;
    border-radius: 6px;
}

.add-to-change:hover {
    background: #f37321;
}

// .effectivity {
//     align-items: center;
//     display: inline-flex;
//     justify-content: flex-start;
//     margin-top: 24px;

// }
// .react-datepicker {
//     width: 100%;
//     input {
//         width: 100%;
//     }
// }
